/* The animation code */
@keyframes example {
  0%   {opacity: 0}
  100% {opacity: 1}
}

.bar1, .bar2, .bar3 {
  width: 30px;
height: 4px;
background-color: #00ADA7;
margin: 6px 0;
transition: 0.4s;
border-radius: 5px;
}

/* Rotate first bar */
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
  transform: rotate(-45deg) translate(-9px, 6px) ;
    background-color: #fff;
}

/* Fade out the second bar */
.change .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
  transform: rotate(45deg) translate(-8px, -6px) ;
    background-color: #fff;
}

.burger {
    cursor: pointer;
}
.change {
    padding: 10px 50% 0px 0px;
    float: right
}


.logo {
    margin-left: 3%;
}
.vinButton {
    padding: 7px 21px;
    background-color: #00ADA7;
    border: none;
    color: #fff;
    border-radius: 2px;
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
}

.vinButton:hover {
    background-color: #008984;
    color: #fff;
    text-decoration: none
}
.vinButtonInverse {
padding: 7px 21px;
    background-color: #fff;
    border: 1px solid #00ADA7;
    color: #00ADA7;
    border-radius: 2px;
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
}
.vinButtonInverse:hover {
    background-color: #008984;
    border: 1px solid #008984;
    color: #fff;
}
.secondaryBtn {
   padding: 1.25rem 2.5rem !important
}
.darkButton {
    background-color: #008984;
}
.darkButton:hover {
    background-color: #006763
}


main {
    margin-top: 90px;
    min-height: calc(100vh - 90px);
    margin-left: auto !important;
    margin-right: auto !important;
    width: 75%;
}


/*from Mailchimp*/
.directoryDetailHero__logo {
position: relative;
margin-bottom: .625rem;
max-width: 6.25rem;
}

a{
color: #00ADA7;
font-weight: 500;
overflow-wrap: break-word;
word-wrap: break-word;
-ms-word-break: break-all;
word-break: break-all;
word-break: break-word;
-webkit-hyphens: auto;
-ms-hyphens: auto;
hyphens: auto;
}
a {
    text-decoration: none;
    cursor: pointer;
}







.modal__content {
max-width: 95%;
width: 40rem;
margin: 0 auto;
background: #fff;
max-height: 95%;
padding: 2rem 0;
position: relative;
top: 50%;
min-height: 30rem;
overflow: auto;
transform: translateY(-50%);
}
.modal {
position: fixed;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
background-color: #fff;
-webkit-transition: all 0.5s ease-in-out;
-moz-transition: all 0.5s ease-in-out;
-ms-transition: all 0.5s ease-in-out;
-o-transition: all 0.5s ease-in-out;
transition: all 0.4s ease;
opacity: 0;
z-index: -100;
background: rgba(0,112,108,0.90);
margin: 0;
}
.modal--open {
opacity: 1;
z-index: 600002;
}
.specialForm label {
font-weight: 600;
color: #008984;
font-size: 0.625rem;
text-transform: uppercase;
display: block;
position: relative;
}
.specialForm input {
width: 100%;
box-sizing: border-box;
border-radius: 0.3125rem;
background: rgb(218, 245, 237, 0.5);
border: 0;
margin: 0.25rem 0;
padding: 0.625rem;
font-weight: 700;
font-size: 0.875rem;
color: #008984;
position: relative;
outline: none;
}
input {
width: 100%;
box-sizing: border-box;
border-radius: 0.3125rem;
border: .5px solid;
margin: 0.25rem 0;
padding: 0.625rem;
font-weight: 700;
font-size: 0.875rem;
position: relative;
outline: none;
}
.form-row {
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-right: -5px;
margin-left: -5px;
}
.form-group {
    margin: .5rem 0
}
form .select-wrapper select {
-webkit-appearance: none;
-moz-appearance: none;
width: 100%;
box-sizing: border-box;
border-radius: 0.3125rem;
background: rgb(218, 245, 237, 0.5);
border: 0;
padding: 0.825rem 1.25rem;
font-weight: 700;
font-size: 0.875rem;
color: #008984;
position: relative;
outline: none;
}
form .select-wrapper:after {
content: '';
background-image: url("data:image/svg+xml,%3Csvg stroke='#008984' fill='#008984' stroke-width='0' viewBox='0 0 24 24' height='1.5em' width='1.5em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'%3E%3C/path%3E%3C/svg%3E");
background-repeat: no-repeat;
background-position: center;
width: 0.625rem;
height: 0.625rem;
right: 2.6875rem;
top: 53%;
position: absolute;
pointer-events: none;
}
form input[type='checkbox'] {
display: inline-block;
width: auto;
margin-right: 0.5rem;
position: absolute;
left: 4px;
opacity: 0;
}
form .fauxBox {
display: inline-block;
height: 20px;
width: 20px;
border-radius: 0.3125rem;
background: rgb(218, 245, 237, 0.5);
vertical-align: middle;
margin-right: 0.5rem;
text-align: center;
line-height: 20px;
font-weight: 700;
font-size: 0.875rem;
color: #008984;
}
form input[type='checkbox']:checked+span:after {
content: "\2713";
}
.discoverLink {
    text-transform: capitalize;
        vertical-align: top;
    hyphens: none;
}

a svg {
fill: #00ADA7
}

a svg:hover {
fill: #008984;
text-decoration: underline;
}

/* loading dots */

.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}
