.leftNav {
  display: none
}

.rightNav {
  padding-right: 11%;
  padding-top: 20px;
  padding-bottom: 145px;
  position: fixed;
  height: 100%;
  right: 0px;
  overflow: scroll;
  margin-top: 100px !important
}

.primeMargin {
  margin-bottom: 100px
}

@media screen and (min-width: 960px) {
  .leftNav {
    display: block;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(204, 204, 204);
    height: 100%;
    margin: auto;
    position: fixed;
    padding-left: 2.5%;
  }

  .rightNav {
    margin-top: 10px
  }
}

.modalStatus {
  width: 100%;
  background-color: rgb(12, 151, 58);
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 2px 16px rgba(0, 0, 0, .1);
  color: #fff;
  height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0;
  transition: opacity .2s linear;
  text-align: center;
}

.is-visible {
  height: auto;
  padding: 12px;
  opacity: 1;
  transition: opacity .2s linear;
}